let eventGuid = 11213120

export const employeesMock = [
    {
        id: createEventId(),
        fullName: 'Андрей Петров',
        name: 'Андрей',
        surname: 'Петров',
        fatherName: 'Васильевич',
        IPphone: '88123456454',
        isPhoneEqualsIPphone: true,
        published_at: '09.03.2021 11:37',
        last_visit: '18.09.2021 00:56',
        isOnline: false,
        isBlocked: false,
        company: 'Агентство недвижимости',
        rank: 'Агент',
        phone: '+79219942379',
        email: 'test@example.com',
        clientsLength: '194',
        objectsLength: '5',
        
        passport: {
          number: '4005 465798',
          date: '05.05.1989',
          department: '73 о/м фрунзенского района Спб',
          address: 'г. Москва, ул. Ленина д.23/5, 645',
          postAddress: '189945, г. Москва, ул. Ленина д.23/5, 645'
        },

        socials: [
          {
              name: 'вКонтакте',
              url:  'https://vk.com/',
              icon:  'vk-icon.png'
          },

          {
            name: 'Instagramm',
            url:  'https://instagramm.com/',
            icon:  'insta-icon.png'
          },
        ],

        docs: [
          {
            title: 'Название документа-1'
          },
          {
            title: 'Название документа-2'
          },
        ]
    },

    {
      id: createEventId(),
        fullName: 'Андрей Петров2',
        name: 'Андрей',
        surname: 'Петров',
        fatherName: 'Васильевич',
        IPphone: '88123456454',
        isPhoneEqualsIPphone: true,
        published_at: '09.03.2021 11:37',
        last_visit: '18.09.2021 00:56',
        isOnline: true,
        isBlocked: false,
        company: 'Агентство недвижимости',
        rank: 'Агент',
        phone: '+79219942379',
        email: 'test@example.com',
        clients: '194',
        objects: '5',
        passport: {
          number: '4005 465798',
          date: '05.05.1989',
          department: '73 о/м фрунзенского района Спб',
          address: 'г. Москва, ул. Ленина д.23/5, 645',
          postAddress: '189945, г. Москва, ул. Ленина д.23/5, 645'
        },

        socials: [
          {
              name: 'вКонтакте',
              url:  'https://vk.com/',
              icon:  'require("@/assets/img/vk-icon.png")'
          },

          {
            name: 'Instagramm',
            url:  'https://instagramm.com/',
            icon:  'require("@/assets/img/vk-icon.png")'
          },
        ]
    },

    {
      id: createEventId(),
        fullName: 'Андрей Петров',
        name: 'Андрей',
        surname: 'Петров',
        fatherName: 'Васильевич',
        IPphone: '88123456454',
        isPhoneEqualsIPphone: true,
        published_at: '09.03.2021 11:37',
        last_visit: '18.09.2021 00:56',
        isOnline: false,
        isBlocked: false,
        company: 'Агентство недвижимости',
        rank: 'Агент',
        phone: '+79219942379',
        email: 'test@example.com',
        clientsLength: '194',
        objectsLength: '5',
        
        passport: {
          number: '4005 465798',
          date: '05.05.1989',
          department: '73 о/м фрунзенского района Спб',
          address: 'г. Москва, ул. Ленина д.23/5, 645',
          postAddress: '189945, г. Москва, ул. Ленина д.23/5, 645'
        },

        socials: [
          {
              name: 'вКонтакте',
              url:  'https://vk.com/',
              icon:  'vk-icon.png'
          },

          {
            name: 'Instagramm',
            url:  'https://instagramm.com/',
            icon:  'insta-icon.png'
          },
        ],

        docs: [
          {
            title: 'Название документа-1'
          },
          {
            title: 'Название документа-2'
          },
        ]
    },

    {
      id: createEventId(),
        fullName: 'Андрей Петров2',
        name: 'Андрей',
        surname: 'Петров',
        fatherName: 'Васильевич',
        IPphone: '88123456454',
        isPhoneEqualsIPphone: true,
        published_at: '09.03.2021 11:37',
        last_visit: '18.09.2021 00:56',
        isOnline: false,
        isBlocked: true,
        company: 'Агентство недвижимости',
        rank: 'Агент',
        phone: '+79219942379',
        email: 'test@example.com',
        clients: '194',
        objects: '5',
        passport: {
          number: '4005 465798',
          date: '05.05.1989',
          department: '73 о/м фрунзенского района Спб',
          address: 'г. Москва, ул. Ленина д.23/5, 645',
          postAddress: '189945, г. Москва, ул. Ленина д.23/5, 645'
        },

        socials: [
          {
              name: 'вКонтакте',
              url:  'https://vk.com/',
              icon:  'require("@/assets/img/vk-icon.png")'
          },

          {
            name: 'Instagramm',
            url:  'https://instagramm.com/',
            icon:  'require("@/assets/img/vk-icon.png")'
          },
        ]
    },

]

export function createEventId() {
  return eventGuid++
}
