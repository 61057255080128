class Session extends Map {
	setCookie(name, value, expires) {
		let now = new Date();
		let time = now.getTime();
		let expired = time + parseInt(expires);
		now.setTime(expired);
		document.cookie = name +'='+ value +';expires=' + now.toUTCString() + ';path=/';
	}
	set(id, value, local) {
		if (typeof value === 'object')
			value = JSON.stringify(value);

		if (local)
			window.localStorage.setItem(id, value);
		else
			window.sessionStorage.setItem(id, value);

		if (id === 'token')
			this.setCookie("PHPSESSID", value, (90*60));

	}
	get(id, local) {

		const value = (local) ? window.localStorage.getItem(id) : window.sessionStorage.getItem(id);

		try {
			return JSON.parse(value);
		} catch (e) {
			return value;
		}
	}
	clear(local) {

		if (local)
			window.localStorage.clear();

		window.sessionStorage.clear();
	}
	support() {
		if (typeof(Storage) !== "undefined")
			return (window.localStorage && window.sessionStorage);
		else
			return false
	}
}

export default new Session();