//import { data } from "jquery";
import { createStore } from "vuex";
import Session from "@/services/Session";

import { employeesMock } from "./mockData/mock-data";
import CommonService from "@/services/CommonService";

const store = createStore({
  state() {
    return {
      config: null,
      path: null,
      page_transition: {
        name: "default",
        mode: "out-in",
      },
      logs: [],
      employeeList: [],
      session_id: Session.get("token") ? Session.get("token") : null,
      user_info: Session.get("user")
        ? Session.get("user")
        : {
            region_id: null,
            region: {
              code: null,
            },
            watermark: null,
            user_can: {},
          },
      options: Session.get("options") ? Session.get("options") : {},
      api_errors: Session.get("api_errors", true)
        ? Session.get("api_errors", true)
        : {
            request: [],
            response: [],
          },
      accounts: Session.get("accounts", true)
        ? Session.get("accounts", true)
        : [],
      forms_state: Session.get("forms_state") ? Session.get("forms_state") : {},
      users_online: Session.get("users_online")
        ? Session.get("users_online")
        : [],
      browser_info: Session.get("browser_info")
        ? Session.get("browser_info")
        : null,
      viewed_events: Session.get("events") ? Session.get("events") : [],
      to_pdf: Session.get("to_pdf") ? Session.get("to_pdf") : [],
      funnels_data: Session.get("funnels_data")
        ? Session.get("funnels_data")
        : [],
      regions_list: Session.get("regions") ? Session.get("regions") : [],
      tags_list: Session.get("tags") ? Session.get("tags") : [],
      sources_list: Session.get("sources") ? Session.get("sources") : [],
      estate_types_list: Session.get("estate_types")
        ? Session.get("estate_types")
        : [],
      partners_list: Session.get("partners") ? Session.get("partners") : [],
      employees_list: Session.get("employees") ? Session.get("employees") : [],
      employees_list_excluded: Session.get("employees2")
        ? Session.get("employees2")
        : [],
      districts_list: Session.get("districts") ? Session.get("districts") : [],
      metro_list: Session.get("metro") ? Session.get("metro") : [],
      to_metro_list: Session.get("to_metro") ? Session.get("to_metro") : [],
      custom_fields_list: Session.get("custom-fields")
        ? Session.get("custom-fields")
        : [],
      search_targets: Session.get("search_targets")
        ? Session.get("search_targets")
        : [],
      autosearch_targets: Session.get("autosearch_targets")
        ? Session.get("autosearch_targets")
        : [],
      obj_operations_list: Session.get("object_operations")
        ? Session.get("object_operations")
        : [],
      obj_types_list: Session.get("object_types")
        ? Session.get("object_types")
        : [],
      obj_reasons_list: Session.get("object_reasons")
        ? Session.get("object_reasons")
        : [],
      clients_types_list: Session.get("clients_types")
        ? Session.get("clients_types")
        : [],
      requisitions_types_list: Session.get("requisitions_types")
        ? Session.get("requisitions_types")
        : [],
      obj_clients_list: Session.get("object_clients")
        ? Session.get("object_clients")
        : [],
      avito_room_list: Session.get("avito_room_types")
        ? Session.get("avito_room_types")
        : [],
      deal_types_list: Session.get("deal_types")
        ? Session.get("deal_types")
        : [],
      house_categories_list: Session.get("house_categories")
        ? Session.get("house_categories")
        : [],
      house_types_list: Session.get("house_types")
        ? Session.get("house_types")
        : [],
      house_materials_list: Session.get("house_materials")
        ? Session.get("house_materials")
        : [],
      lease_list: Session.get("lease") ? Session.get("lease") : [],
      commercial_list: Session.get("commercial")
        ? Session.get("commercial")
        : [],
      layouts_list: Session.get("layouts") ? Session.get("layouts") : [],
      first_floor_list: Session.get("first_floor")
        ? Session.get("first_floor")
        : [],
      req_types: Session.get("req_types") ? Session.get("req_types") : [],
      req_funnels: Session.get("req_funnels") ? Session.get("req_funnels") : [],
      req_objects: Session.get("req_objects") ? Session.get("req_objects") : [],
      req_clients: Session.get("req_clients") ? Session.get("req_clients") : [],

      clients_funnels: Session.get("clients_funnels")
        ? Session.get("clients_funnels")
        : [],
      requisitions_funnels: Session.get("requisitions_funnels")
        ? Session.get("requisitions_funnels")
        : [],

      clients_roles: Session.get("clients_roles")
        ? Session.get("clients_roles")
        : [],
      requisitions_roles: Session.get("requisitions_roles")
        ? Session.get("requisitions_roles")
        : [],
      unread_messages_count: {
        cian: 0,
        avito: 0,
      },
      cian_chat_ids: [],
      avito_chat_ids: [],
      need_refetch_messages: {
        avito: false,
        cian: false,
      },
    };
  },

  mutations: {
    setConfig(state, config) {
      state.config = config;
      Session.set("config", state.config);

      if (process.env.NODE_ENV == "development")
        console.log("setConfig()", state.config);
    },

    setSessionId(state, session_id) {
      state.session_id = session_id;
      Session.set("token", state.session_id, true);

      if (process.env.NODE_ENV == "development")
        console.log("setSessionId()", state.session_id);
    },

    setPageTransition(state, transition_name, transition_mode) {
      if (!CommonService.isEmpty(transition_name))
        state.page_transition.name = transition_name;

      if (!CommonService.isEmpty(transition_mode))
        state.page_transition.mode = transition_mode;
      else state.page_transition.mode = "out-in";

      if (process.env.NODE_ENV == "development")
        console.log("setPageTransition()", state.page_transition);
    },

    setLog(state, log) {
      state.logs.push(log);

      if (process.env.NODE_ENV == "development")
        console.log("setLog()", state.logs);
    },

    setOptions(state, list) {
      state.options = list;
      Session.set("options", state.options);

      if (process.env.NODE_ENV == "development")
        console.log("setOptions()", state.options);
    },

    setAccounts(state, accounts) {
      state.accounts = accounts;
      Session.set("accounts", state.accounts, true);

      if (process.env.NODE_ENV == "development")
        console.log("setAccounts()", state.accounts);
    },
    addAccounts(state, accounts) {
      state.accounts = [...state.accounts, ...accounts];
      Session.set("accounts", state.accounts, true);

      if (process.env.NODE_ENV == "development")
        console.log("addAccounts()", state.accounts);
    },

    addApiErrors(state, data) {
      state.api_errors[data.type].push(data.error);

      Session.set("api_errors", state.api_errors, true);

      if (process.env.NODE_ENV == "development")
        console.log("addApiErrors()", state.api_errors);
    },

    addAccount(state, account) {
      state.accounts = state.accounts.filter((item) => item.id !== account.id);

      if (state.accounts) {
        // Check lifetime session of expires
        let datetime = CommonService.dateAdd(
          new Date(),
          -180,
          "minute",
          true,
          false
        );
        state.accounts = state.accounts.filter(
          (item) => item.timestamp >= datetime
        );
      }

      state.accounts.push(account);
      Session.set("accounts", state.accounts, true);
      CommonService.log("info", "addAccount()::state", state.accounts);
    },
    removeAccount(state, account_id) {
      state.accounts = state.accounts.filter((item) => item.id !== account_id);
      CommonService.log("info", "removeAccount()::state", state.accounts);
      Session.set("accounts", state.accounts, true);
    },

    setUserOnline(state, list) {
      state.users_online = list;
      Session.set("users_online", state.users_online);

      if (process.env.NODE_ENV == "development")
        console.log("setUserOnline()", state.users_online);
    },

    setFormsState(state, form_state) {
      state.forms_state = { ...state.forms_state, ...form_state };
      Session.set("forms_state", state.forms_state);

      if (process.env.NODE_ENV == "development")
        console.log("setFormsState()", state.forms_state);
    },

    resetFormsState(state, form_name) {
      state.forms_state[form_name] = null;
      Session.set("forms_state", state.forms_state);

      if (process.env.NODE_ENV == "development")
        console.log("resetFormsState()", state.forms_state);
    },

    setBrowserInfo(state, browser) {
      state.browser_info = browser;
      Session.set("browser_info", state.browser_info);

      if (process.env.NODE_ENV == "development")
        console.log("setBrowserInfo()", state.browser_info);
    },

    setRedirectPath(state, redirect_path) {
      state.redirect_path = redirect_path;
      Session.set("path", state.redirect_path);

      if (process.env.NODE_ENV == "development")
        console.log("setRedirectPath()", state.redirect_path);
    },
    setUserInfo(state, user) {
      state.user_info = user;
      Session.set("user", state.user_info);

      if (process.env.NODE_ENV == "development")
        console.log("setUserInfo()", state.user_info);
    },
    addUserInfo(state, user) {
      state.user_info = [...state.user_info, ...user];
      Session.set("user", state.user_info);

      if (process.env.NODE_ENV == "development")
        console.log("addUserInfo()", state.user_info);
    },

    setViewedEvents(state, events_ids) {
      state.viewed_events = events_ids;
      Session.set("events", state.viewed_events);

      if (process.env.NODE_ENV == "development")
        console.log("setViewedEvents()", state.viewed_events);
    },
    addViewedEvents(state, events_ids) {
      state.viewed_events = [...state.viewed_events, ...events_ids];
      Session.set("events", state.viewed_events);

      if (process.env.NODE_ENV == "development")
        console.log("addViewedEvents()", state.viewed_events);
    },
    addViewedEvent(state, event_id) {
      state.viewed_events.push(event_id);
      Session.set("events", state.viewed_events);

      if (process.env.NODE_ENV == "development")
        console.log("addViewedEvent()", state.viewed_events);
    },

    clearToPDF(state) {
      state.to_pdf = [];
      Session.set("to_pdf", state.to_pdf);

      if (process.env.NODE_ENV == "development")
        console.log("clearToPDF()", state.to_pdf);
    },
    addObjectToPDF(state, object_id) {
      state.to_pdf.push(object_id);
      Session.set("to_pdf", state.to_pdf);

      if (process.env.NODE_ENV == "development")
        console.log("addObjectToPDF()", state.to_pdf);
    },
    removeObjectFromPDF(state, object_id) {
      let index = state.to_pdf.indexOf(object_id);
      if (index !== -1) state.to_pdf.splice(index, 1);

      Session.set("to_pdf", state.to_pdf);

      if (process.env.NODE_ENV == "development")
        console.log("addObjectToPDF()", state.to_pdf);
    },
    setObjectsToPDF(state, objects) {
      state.to_pdf = objects;
      Session.set("to_pdf", state.to_pdf);

      if (process.env.NODE_ENV == "development")
        console.log("setObjectsToPDF()", state.to_pdf);
    },

    setObjectOperationsList(state, list) {
      state.obj_operations_list = list;
      Session.set("object_operations", state.obj_operations_list);

      if (process.env.NODE_ENV == "development")
        console.log("setObjectOperationsList()", state.obj_operations_list);
    },
    addObjectOperationsList(state, list) {
      state.obj_operations_list = [...state.obj_operations_list, ...list];
      Session.set("object_operations", state.obj_operations_list);

      if (process.env.NODE_ENV == "development")
        console.log("addObjectOperationsList()", state.obj_operations_list);
    },

    setSearchTargets(state, list) {
      state.search_targets = list;
      Session.set("search_targets", state.search_targets);

      if (process.env.NODE_ENV == "development")
        console.log("setSearchRargets()", state.search_targets);
    },
    setAutoSearchTargets(state, list) {
      state.autosearch_targets = list;
      Session.set("autosearch_targets", state.autosearch_targets);

      if (process.env.NODE_ENV == "development")
        console.log("setAutoSearchTargets()", state.autosearch_targets);
    },
    addSearchTargets(state, list) {
      state.search_targets = [...state.search_targets, ...list];
      Session.set("search_targets", state.search_targets);

      if (process.env.NODE_ENV == "development")
        console.log("addSearchRargets()", state.search_targets);
    },
    addAutoSearchTargets(state, list) {
      state.autosearch_targets = [...state.autosearch_targets, ...list];
      Session.set("autosearch_targets", state.autosearch_targets);

      if (process.env.NODE_ENV == "development")
        console.log("addAutoSearchTargets()", state.autosearch_targets);
    },

    setFunnelsData(state, list) {
      state.funnels_data = list;
      Session.set("funnels_data", state.funnels_data);

      if (process.env.NODE_ENV == "development")
        console.log("setFunnelsData()", state.funnels_data);
    },

    setRegionsList(state, list) {
      state.regions_list = list;
      Session.set("regions", state.regions_list);

      if (process.env.NODE_ENV == "development")
        console.log("setRegionsList()", state.regions_list);
    },
    addRegionsList(state, list) {
      state.regions_list = [...state.regions_list, ...list];
      Session.set("regions", state.regions_list);

      if (process.env.NODE_ENV == "development")
        console.log("addRegionsList()", state.regions_list);
    },

    setObjectTypesList(state, list) {
      state.obj_types_list = list;
      Session.set("object_types", state.obj_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("setLeaseList()", state.obj_types_list);
    },

    setReasonsList(state, list) {
      state.obj_reasons_list = list;
      Session.set("object_reasons", state.obj_reasons_list);

      if (process.env.NODE_ENV == "development")
        console.log("setReasonsList()", state.obj_reasons_list);
    },

    setClientsTypesList(state, list) {
      state.clients_types_list = list;
      Session.set("clients_types", state.clients_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("setClientsTypesList()", state.clients_types_list);
    },

    setRequisitionsTypesList(state, list) {
      state.requisitions_types_list = list;
      Session.set("requisitions_types", state.requisitions_types_list);

      if (process.env.NODE_ENV == "development")
        console.log(
          "setRequisitionsTypesList()",
          state.requisitions_types_list
        );
    },

    addObjectTypesList(state, list) {
      state.obj_types_list = [...state.obj_types_list, ...list];
      Session.set("object_types", state.obj_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("addLeaseList()", state.obj_types_list);
    },

    setObjectClientsList(state, list) {
      state.obj_clients_list = list;
      Session.set("object_clients", state.obj_clients_list);

      if (process.env.NODE_ENV == "development")
        console.log("setObjectClientsList()", state.obj_clients_list);
    },
    addObjectClientsList(state, list) {
      state.obj_clients_list = [...state.obj_clients_list, ...list];
      Session.set("object_clients", state.obj_clients_list);

      if (process.env.NODE_ENV == "development")
        console.log("addObjectClientsList()", state.obj_clients_list);
    },

    setAvitoRoomTypesList(state, list) {
      state.avito_room_list = list;
      Session.set("avito_room_types", state.avito_room_list);

      if (process.env.NODE_ENV == "development")
        console.log("setAvitoRoomTypesList()", state.avito_room_list);
    },
    addAvitoRoomTypesList(state, list) {
      state.avito_room_list = [...state.avito_room_list, ...list];
      Session.set("avito_room_types", state.avito_room_list);

      if (process.env.NODE_ENV == "development")
        console.log("addAvitoRoomTypesList()", state.avito_room_list);
    },

    setDealTypesList(state, list) {
      state.deal_types_list = list;
      Session.set("deal_types", state.deal_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("setDealTypesList()", state.deal_types_list);
    },
    addDealTypesList(state, list) {
      state.deal_types_list = [...state.deal_types_list, ...list];
      Session.set("deal_types", state.deal_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("addDealTypesList()", state.deal_types_list);
    },

    setHouseCategoriesList(state, list) {
      state.house_categories_list = list;
      Session.set("house_categories", state.house_categories_list);

      if (process.env.NODE_ENV == "development")
        console.log("setHouseCategoriesList()", state.house_categories_list);
    },
    addHouseCategoriesList(state, list) {
      state.house_categories_list = [...state.house_categories_list, ...list];
      Session.set("house_categories", state.house_categories_list);

      if (process.env.NODE_ENV == "development")
        console.log("addHouseCategoriesList()", state.house_categories_list);
    },

    setHouseTypesList(state, list) {
      state.house_types_list = list;
      Session.set("house_types", state.house_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("setHouseTypesList()", state.house_types_list);
    },
    addHouseTypesList(state, list) {
      state.house_types_list = [...state.house_types_list, ...list];
      Session.set("house_types", state.house_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("addHouseTypesList()", state.house_types_list);
    },

    setHouseMaterialsList(state, list) {
      state.house_materials_list = list;
      Session.set("house_materials", state.house_materials_list);

      if (process.env.NODE_ENV == "development")
        console.log("setHouseMaterialsList()", state.house_materials_list);
    },
    addHouseMaterialsList(state, list) {
      state.house_materials_list = [...state.house_materials_list, ...list];
      Session.set("house_materials", state.house_materials_list);

      if (process.env.NODE_ENV == "development")
        console.log("addHouseMaterialsList()", state.house_materials_list);
    },

    setLeaseList(state, list) {
      state.lease_list = list;
      Session.set("lease", state.lease_list);

      if (process.env.NODE_ENV == "development")
        console.log("setLeaseList()", state.lease_list);
    },
    addLeaseList(state, list) {
      state.lease_list = [...state.lease_list, ...list];
      Session.set("lease", state.lease_list);

      if (process.env.NODE_ENV == "development")
        console.log("addLeaseList()", state.lease_list);
    },

    setSourcesList(state, list) {
      state.sources_list = list;
      Session.set("sources", state.sources_list);

      if (process.env.NODE_ENV == "development")
        console.log("setSourcesList()", state.sources_list);
    },

    setEstateTypesList(state, list) {
      state.estate_types_list = list;
      Session.set("estate_types", state.estate_types_list);

      if (process.env.NODE_ENV == "development")
        console.log("setEstateTypesList()", state.estate_types_list);
    },

    setPartnersList(state, list) {
      state.partners_list = list;
      Session.set("partners", state.partners_list);

      if (process.env.NODE_ENV == "development")
        console.log("setPartnersList()", state.partners_list);
    },

    setTagsList(state, list) {
      state.tags_list = list;
      Session.set("tags", state.tags_list);

      if (process.env.NODE_ENV == "development")
        console.log("setTagsList()", state.tags_list);
    },
    addTagsList(state, list) {
      state.tags_list = [...state.tags_list, ...list];
      Session.set("tags", state.tags_list);

      if (process.env.NODE_ENV == "development")
        console.log("addTagsList()", state.tags_list);
    },

    setEmployeesList(state, list) {
      state.employees_list = list;
      Session.set("employees", state.employees_list);

      if (process.env.NODE_ENV == "development")
        console.log("setEmployeesList()", state.employees_list);
    },
    addEmployeesList(state, list) {
      state.employees_list = [...state.employees_list, ...list];
      Session.set("employees", state.employees_list);

      if (process.env.NODE_ENV == "development")
        console.log("addEmployeesList()", state.employees_list);
    },

    setEmployeesListExcluded(state, list) {
      state.employees_list_excluded = list;
      Session.set("employees2", state.employees_list_excluded);

      if (process.env.NODE_ENV == "development")
        console.log(
          "setEmployeesListExcluded()",
          state.employees_list_excluded
        );
    },

    setDistrictsList(state, list) {
      state.districts_list = list;
      Session.set("districts", state.districts_list);

      if (process.env.NODE_ENV == "development")
        console.log("setDistrictsList()", state.districts_list);
    },
    addDistrictsList(state, list) {
      state.districts_list = [...state.districts_list, ...list];
      Session.set("districts", state.districts_list);

      if (process.env.NODE_ENV == "development")
        console.log("addDistrictsList()", state.districts_list);
    },

    setMetroList(state, list) {
      state.metro_list = list;
      Session.set("metro", state.metro_list);

      if (process.env.NODE_ENV == "development")
        console.log("setMetroList()", state.metro_list);
    },
    addMetroList(state, list) {
      state.metro_list = [...state.metro_list, ...list];
      Session.set("metro", state.metro_list);

      if (process.env.NODE_ENV == "development")
        console.log("addMetroList()", state.metro_list);
    },

    setToMetroTypesList(state, list) {
      state.to_metro_list = list;
      Session.set("to_metro", state.to_metro_list);

      if (process.env.NODE_ENV == "development")
        console.log("setToMetroTypesList()", state.to_metro_list);
    },
    addToMetroTypesList(state, list) {
      state.to_metro_list = [...state.to_metro_list, ...list];
      Session.set("to_metro", state.to_metro_list);

      if (process.env.NODE_ENV == "development")
        console.log("addToMetroTypesList()", state.to_metro_list);
    },

    setCustomFieldsList(state, list) {
      state.custom_fields_list = list;
      Session.set("custom-fields", state.custom_fields_list);

      if (process.env.NODE_ENV == "development")
        console.log("getCustomFieldsList()", state.custom_fields_list);
    },
    addToCustomFieldsList(state, list) {
      state.custom_fields_list = [...state.custom_fields_list, ...list];
      Session.set("custom-fields", state.custom_fields_list);

      if (process.env.NODE_ENV == "development")
        console.log("addToCustomFieldsList()", state.custom_fields_list);
    },

    setCommercialList(state, list) {
      state.commercial_list = !list
        ? list
        : list.map((l) => ({ ...l, checked: false }));
      Session.set("commercial", state.commercial_list);

      if (process.env.NODE_ENV == "development")
        console.log("setCommercialList()", state.commercial_list);
    },
    addCommercialList(state, list) {
      state.commercial_list = [...state.commercial_list, ...list];
      Session.set("commercial", state.commercial_list);

      if (process.env.NODE_ENV == "development")
        console.log("addCommercialList()", state.commercial_list);
    },

    setLayoutsList(state, list) {
      state.layouts_list = list;
      Session.set("layouts", state.layouts_list);

      if (process.env.NODE_ENV == "development")
        console.log("setLayoutsList()", state.layouts_list);
    },
    addLayoutsList(state, list) {
      state.layouts_list = [...state.layouts_list, ...list];
      Session.set("layouts", state.layouts_list);

      if (process.env.NODE_ENV == "development")
        console.log("addLayoutsList()", state.layouts_list);
    },

    setFirstFloorList(state, list) {
      state.first_floor_list = list;
      Session.set("first_floor", state.first_floor_list);

      if (process.env.NODE_ENV == "development")
        console.log("setFirstFloorList()", state.first_floor_list);
    },
    addFirstFloorList(state, list) {
      state.first_floor_list = [...state.first_floor_list, ...list];
      Session.set("first_floor", state.first_floor_list);

      if (process.env.NODE_ENV == "development")
        console.log("addFirstFloorList()", state.first_floor_list);
    },

    setReqTypes(state, types) {
      state.req_types = types;
      Session.set("req_types", state.req_types);

      if (process.env.NODE_ENV == "development")
        console.log("setReqTypes()", state.req_types);
    },
    addReqTypes(state, types) {
      state.req_types = [...state.req_types, ...types];
      Session.set("req_types", state.req_types);

      if (process.env.NODE_ENV == "development")
        console.log("addReqTypes()", state.req_types);
    },

    setReqFunnels(state, funnels) {
      state.req_funnels = funnels;
      Session.set("req_funnels", state.req_funnels);

      if (process.env.NODE_ENV == "development")
        console.log("setReqFunnels()", state.req_funnels);
    },
    addReqFunnels(state, funnels) {
      state.req_funnels = [...state.req_funnels, ...funnels];
      Session.set("req_funnels", state.req_funnels);

      if (process.env.NODE_ENV == "development")
        console.log("addReqFunnels()", state.req_funnels);
    },

    setReqObjects(state, objects) {
      state.req_objects = objects;
      Session.set("req_objects", state.req_objects);

      if (process.env.NODE_ENV == "development")
        console.log("setReqObjects()", state.req_objects);
    },
    addReqObjects(state, objects) {
      state.req_objects = [...state.req_objects, ...objects];
      Session.set("req_objects", state.req_objects);

      if (process.env.NODE_ENV == "development")
        console.log("addReqObjects()", state.req_objects);
    },

    setReqClients(state, clients) {
      state.req_clients = clients;
      Session.set("req_clients", state.req_clients);

      if (process.env.NODE_ENV == "development")
        console.log("setReqClients()", state.req_clients);
    },
    addReqClients(state, clients) {
      state.req_clients = [...state.req_clients, ...clients];
      Session.set("req_clients", state.req_clients);

      if (process.env.NODE_ENV == "development")
        console.log("addReqClients()", state.req_clients);
    },

    setRolesList(state, data) {
      let section = data.section;
      if (section == "clients") {
        state.clients_roles = data.list;
        Session.set("clients_roles", state.clients_roles);

        if (process.env.NODE_ENV == "development")
          console.log("setFunnelsList()::clients", state.clients_roles);
      } else if (section == "requisitions") {
        state.requisitions_roles = data.list;
        Session.set("requisitions_roles", state.requisitions_roles);

        if (process.env.NODE_ENV == "development")
          console.log(
            "setFunnelsList()::requisitions",
            state.requisitions_roles
          );
      }
    },

    setFunnelsList(state, data) {
      let section = data.section;
      if (section == "clients") {
        state.clients_funnels = data.list;
        Session.set("clients_funnels", state.clients_funnels);

        if (process.env.NODE_ENV == "development")
          console.log("setFunnelsList()::clients", state.clients_funnels);
      } else if (section == "requisitions") {
        state.requisitionss_funnels = data.list;
        Session.set("requisitions_funnels", state.requisitions_funnels);

        if (process.env.NODE_ENV == "development")
          console.log(
            "setFunnelsList()::requisitions",
            state.requisitions_funnels
          );
      }
    },
    addFunnelsList(state, data) {
      let section = data.section;
      if (section == "clients") {
        state.clients_funnels = [...state.clients_funnels, ...data.list];
        Session.set("clients_funnels", state.clients_funnels);

        if (process.env.NODE_ENV == "development")
          console.log("addReqClients()::clients", state.clients_funnels);
      } else if (section == "requisitions") {
        state.requisitions_funnels = [
          ...state.requisitions_funnels,
          ...data.list,
        ];
        Session.set("requisitions_funnels", state.requisitions_funnels);

        if (process.env.NODE_ENV == "development")
          console.log(
            "addReqClients()::requisitions",
            state.requisitions_funnels
          );
      }
    },

    // Employees

    set_employees(state, data) {
      state.employeeList = data;
    },

    set_unread_messages_count(state, payload) {
      state.unread_messages_count = {
        ...state.unread_messages_count,
        ...payload,
      };
    },

    set_chat_ids(state, payload) {
      state[payload.chat_type] = Array.from(
        new Set([...state[payload.chat_type], ...payload.chatIds])
      );
    },

    set_need_refetch_messages(state, payload) {
      state.need_refetch_messages[payload.section] = payload.value;
    },

    // End employees
  },

  actions: {
    get_employees({ commit }) {
      commit("set_employees", employeesMock);
    },

    update_employees({ dispatch }, data) {
      console.log(data, "update");
      employeesMock.push(JSON.parse(data));
      dispatch("get_employees");
      console.log(employeesMock);
    },
  },

  getters: {
    config: (state) => state.config,
    redirectPath: (state) => state.redirect_path,
    sessionId: (state) => state.session_id,
    pageTransition: (state) => state.page_transition,
    logs: (state) => state.logs,
    options: (state) => state.options,
    apiErrors: (state) => state.api_errors,
    userAccounts: (state) => state.accounts,
    userInfo: (state) => state.user_info,
    usersOnLine: (state) => state.users_online,
    browserInfo: (state) => state.browser_info,
    employeeList: (state) => state.employeeList,
    viewedEvents: (state) => state.viewed_events,
    toPDFList: (state) => state.to_pdf,
    sourcesList: (state) => state.sources_list,
    estateTypesList: (state) => state.estate_types_list,
    partnersList: (state) => state.partners_list,
    employeesList: (state) => state.employees_list,
    employeesListExcluded: (state) => state.employees_list_excluded,
    tagsList: (state) => state.tags_list,
    objectsTypesList: (state) => state.obj_types_list,
    objectsReasonsList: (state) => state.obj_reasons_list,
    clientsTypesList: (state) => state.clients_types_list,
    requisitionsTypesList: (state) => state.requisitions_types_list,
    searchTargets: (state) => state.search_targets,
    autoSearchTargets: (state) => state.autosearch_targets,
    commercialList: (state) => state.commercial_list,
    regionsList: (state) => state.regions_list,
    funnelsData: (state) => state.funnels_data,
    formState: (state) => state.forms_state,
    reqFunnels: (state) => state.req_funnels,
    requisitionsFunnels: (state) => state.requisitions_funnels,
    clientsFunnels: (state) => state.clients_funnels,
    clientsRolesList: (state) => state.clients_roles,
    requisitionsRolesList: (state) => state.requisitions_roles,
    activeEmployees: (state) =>
      state.employeeList.filter((emp) => !emp.isBlocked),
    blockedEmployees: (state) =>
      state.employeeList.filter((emp) => emp.isBlocked),
    onlineEmployees: (state) =>
      state.employeeList.filter((emp) => emp.isOnline),
    unread_messages_count: (state) => state.unread_messages_count,
    cian_chat_ids: (state) => state.cian_chat_ids,
    avito_chat_ids: (state) => state.avito_chat_ids,
    need_refetch_messages: (state) => state.need_refetch_messages,
  },
});

export default store;
