<template>
    <div class="footer d-flex mt-auto">
        <div class="container px-4 pb-2">
			<div v-show="this.is_ios && this.inArray(this.$route.name, ['login', 'register', 'restore'])" class="row popover-container">
				<div class="col mb-3 d-inline-flex align-content-center p-2 rounded-4 bg-gray"
					 data-bs-toggle="popover"
					 data-bs-placement="top"
					 :data-bs-target="'#helpUsageApp'"
					 data-bs-container="body">
					<span class="d-inline-flex my-auto rounded-3 overflow-hidden">
						<img :src="require('@/assets/img/joywork-app.png')"
							 alt="JoyWork App"
							 width="48"
							 height="48" />
					</span>
					<span class="d-inline-flex ms-3 my-auto">Установите веб-приложение<br/> JoyWork CRM 🔥</span>
				</div>
				<div class="d-none">
					<div id="helpUsageApp" class="p-3">
						<p class="pe-2 mb-1">Чтобы установить наше веб-приложение,<br/>
							нажмите <img :src="require('@/assets/icons/share-icon-ios.svg')"
								 alt="Share Icon"
								 width="18"
								 height="18" /> и выберите «На экран Домой».</p>
						<p>После чего, Вы сможете найти знакомую вам иконку
							<img :src="require('@/assets/img/joywork-app.png')"
								 alt="JoyWork App"
								 width="24"
								 height="24" />
							на рабочем столе вашего устройства.</p>
					</div>
				</div>
			</div>
            <div class="row">
                <div class="col">
                    <p>
						JoyWork, {{ this.year }}
						<br/>
						<router-link to="/status" class="link">{{ this.version }}<sup v-if="this.version_is_beta" class="text-success">β</sup></router-link>
						<span class="d-none">{{ this.build }}</span>
					</p>
                </div>
                <div class="col d-flex align-items-end">
					<p class="d-flex mt-auto ms-auto me-0">
						<a href="https://joywork.ru/" target="_self" class="link">
							Версия для ПК
						</a>
					</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import CommonService from "@/services/CommonService";

	export default {
        name: "Footer",
		data() {
			return {
				year: new Date().getFullYear(),
				version: process.env.VERSION ?? 'v.3.1.1',
				version_is_beta: process.env.IS_BETA ?? false,
				build: process.env.BUILD ?? null,
				release_date: process.env.RELEASE_DATE ?? null,
			}
		},
		components: {
		},
		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			initPopovers() {
				setTimeout(() => {
					let containers = document.querySelectorAll('.popover-container')
					let selector   = '[data-bs-toggle="popover"]'

					containers.forEach(container => {
						CommonService.popoversInit(container, selector)
					});
				}, 100);
			}
		},
		mounted() {
			this.initPopovers();
			console.log('App version:', this.version + ' ' + (this.version_is_beta ? 'β' : '') + '(' + this.build + '), release out of ' + this.release_date);
		},
		computed: {
			is_ios() {
				return (process.env.NODE_ENV == "development") || CommonService.isiOS();
			},
		}
    };
</script>

<style lang="scss">
    .footer {
        p {
            font-size: 14px;
            line-height: 17px;
            color: #666666;
        }
    }
</style>