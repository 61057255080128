<template>
	<div class="loader col w-100 px-20" :class="this.$props.className">
		<img :src="require('@/assets/svg/rocket-spinner.svg')"
			 class="d-flex align-self-center mx-auto my-5 w-25 h-25"
			 alt="Загрузка..." />
	</div>
</template>

<script>
    export default {
        name: "Loader",
		props: {
			className: {
				type: String
			}
		},
        components: {

        }
    };
</script>

<style lang="scss">
    .loader img {
        max-width: 120px;
    }
</style>