import { createApp } from 'vue'
import App from './App.vue'
import * as Popper from 'popper.js'
import * as bootstrap from 'bootstrap'
import './assets/styles/app.scss'
import router from './router'
import i18n from './plugins/i18n'
import http from "./http";
import store from "./store";
import Maska from 'maska'
import VueSmoothScroll from 'vue3-smooth-scroll'
import CKEditor from '@ckeditor/ckeditor5-vue';
import mitt from 'mitt';
import VueClipboard from 'vue3-clipboard';

const emitter = mitt();
const translations = {
    greetings: {
        hi: 'Здравствуйте!'
    }
};

const scroll = {
    duration: 500,       // animation duration in ms
    offset: 0,           // offset in px from scroll element, can be positive or negative
    //container: '',       // selector string or Element for scroll container, default is window
    updateHistory: true, // whether to push hash to history
    easingFunction: null // gives the ability to provide a custom easing function `t => ...`
                         // (see https://gist.github.com/gre/1650294 for examples)
                         // if nothing is given, it will defaults to `easeInOutCubic`
};

const clipboard = {
    autoSetContainer: true,
    appendToBody: true,
};

const app = createApp(App)
    .use(VueSmoothScroll, scroll)
    .use(router, http)
    .use(i18n, Popper, bootstrap, translations)
    .use(store)
    .use(Maska)
    .use(CKEditor)
    .use(VueClipboard, clipboard);

app.config.globalProperties.emitter = emitter;

app.mount('#app');
