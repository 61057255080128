<template>
	<div :class="this.class_name">
		<img :src="this.user_logo"
			 :alt="this.user_name"
			 :data-user-id="this.user_id"
			 :data-agency-id="this.agency_id"
			 :width="this.logo_size"
			 :height="this.logo_size"
			 class="d-block rounded-circle" />
		<span v-if="this.show_is_online && this.is_online"
			  class="position-absolute rounded-circle bg-success border border-white"
			  style="width:12px;height:12px;bottom:0px;right:0px;" />
	</div>
</template>

<script>
    import CommonService from "@/services/CommonService";

	export default {
        name: "UserPic",
		props: {
			userId: { type: [String, Number] },
			agencyId: { type: [String, Number] },
			userLogo: { type: String },
			userName: { type: String },
			logoSize: { type: [String, Number] },
			className: { type: String },
			showIsOnline: { type: Boolean },
		},
        methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
        },
		computed: {
			is_online() {

				let online_users = this.$store.getters.usersOnLine;
				if (!this.isEmpty(online_users))
					return CommonService.inArray(this.user_id, online_users);

				return false;
			},
			show_is_online() {
				if (!this.isEmpty(this.$props.showIsOnline))
					return (this.$props.showIsOnline);
				else
					return false;
			},
			user_id() {
				if (!this.isEmpty(this.$props.userId))
					return parseInt(this.$props.userId);
				else
					return null;
			},
			agency_id() {
				if (!this.isEmpty(this.$props.agencyId))
					return parseInt(this.$props.agencyId);
				else
					return null;
			},
			user_logo() {
				if (!this.isEmpty(this.$props.userLogo))
					return this.$props.userLogo;
				else
					return require('@/assets/img/user_avatar.png');
			},
			user_name() {
				if (!this.isEmpty(this.$props.userName))
					return this.$props.userName;
				else
					return 'Аватар';
			},
			logo_size() {
				if (!this.isEmpty(this.$props.logoSize))
					return parseInt(this.$props.logoSize);
				else
					return 60;
			},
			class_name() {
				if (!this.isEmpty(this.$props.className))
					return 'd-flex rounded-circle position-relative ' + this.$props.className.toString();
				else
					return 'd-flex rounded-circle position-relative border-1 border-dark mb-auto shadow';
			},
		}
    };
</script>