<template>
	<div v-show="this.count > 0"
		 class="position-fixed w-100 h-auto bottom-0 start-0 end-0 m-0 mt-auto p-3"
		 style="pointer-events: all; z-index: 1043; background: rgba(255,255,255,.8);"
		 :data-count="this.count">
		<div class="toast-container position-static max-vh-80 scroller scroller-y nowrap-mask mt-3 pt-4 pb-2"
			 style="pointer-events: all;">
			<div v-for="event of this.events"
				 :key="event.id"
				 :data-id="event.id"
				 class="toast flex flex-column w-100 mb-2"
				 :class="{'show': !this.hasViewedEvent('event_' + event.id)}"
				 role="alert"
				 aria-live="assertive"
				 aria-atomic="true">
				<div class="toast-header bg-primary text-white">
					<strong class="me-auto" v-text="event.subject" />
					<small class="ps-2 text-light" :title="event.datetime" v-text="this.formatDateTime(event.datetime)" />
					<button type="button"
							class="d-flex ms-auto btn-close"
							data-bs-dismiss="toast"
							aria-label="Close"
							@click="this.dismissEvent('event_' + event.id)"/>
				</div>
				<div class="toast-body w-100 bg-white">
					<div v-if="event.message" v-html="event.message" />
					<div v-if="event.type == 'transfer-object'"
						 class="d-flex mt-1 align-content-center">
						<button type="button"
								class="ms-auto btn btn-sm btn-outline-secondary px-3"
								@click="this.setObjectTransferStatus('rejected', event.id, event.object_id)">Нет</button>
						<button type="button"
								class="ms-2 me-0 btn btn-sm text-white btn-primary px-3"
								@click="this.setObjectTransferStatus('accepted', event.id, event.object_id)">Да</button>
					</div>
					<div v-if="event.type == 'transfer-client'"
						 class="d-flex mt-1 align-content-center">
						<button type="button"
								class="ms-auto btn btn-sm btn-outline-secondary px-3"
								@click="this.setClientTransferStatus('rejected', event.id, event.sender_id, event.client_id, event.requisition_id, event.tr)">Нет</button>
						<button type="button"
								class="ms-2 me-0 btn btn-sm text-white btn-primary px-3"
								@click="this.setClientTransferStatus('accepted', event.id, event.sender_id, event.client_id, event.requisition_id, event.tr)">Да</button>
					</div>
				</div>
			</div>
		</div>
		<div class="position-absolute top-0 end-0 p-3 pt-3 pb-2">
			<button type="button" 
					class="btn btn-sm text-white btn-primary" 
					@click="this.acceptAllEvents()"
					v-text="'Одобрить все'" />
			<button type="button" 
					class="ms-2 btn btn-sm bg-white btn-outline-secondary" 
					@click="this.dismissAllEvents()"
					v-text="'Очистить все'" />
		</div>
	</div>
</template>

<script>
	import api from "@/api";
	import * as bootstrap from 'bootstrap';
	import CommonService from "../../services/CommonService";

	export default {
        name: "EventsList",
		props: {
			eventsList: { type: [Array, Object] }
		},
        data() {
            return {
				events: (typeof this.eventsList !== "undefined") ? this.eventsList : [],
            }
        },
        methods: {
			formatDateTime(datetime) {
				return CommonService.formatDateTime(datetime, 'ru');
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			hasViewedEvent(event_id) {
				return ([...this.$store.getters.viewedEvents].indexOf(event_id) !== -1)
			},
			dismissEvent(event_id) {
				this.$store.commit('addViewedEvent', event_id);

				let viewed = [];
				if (!this.isEmpty(this.$store.getters.viewedEvents))
					viewed = this.$store.getters.viewedEvents;

				let events = [];
				this.events.forEach(item => {
					if (!this.inArray('event_' + item.id, viewed))
						events.push(item);
				});

				this.events = events;
			},
			acceptAllEvents() {
				let _this = this;
				Object.entries(this.events).forEach(([key, event]) => {
					if (event.type == 'transfer-object')
						_this.setObjectTransferStatus('accepted', event.id, event.object_id);
					else if (event.type == 'transfer-client')
						_this.setClientTransferStatus('accepted', event.id, event.sender_id, event.client_id, event.requisition_id, event.tr);
				});
				this.events = [];
			},
			dismissAllEvents() {
				let _this = this;
				Object.entries(this.events).forEach(([key, event]) => {
					let event_id = 'event_' + event.id;
					_this.dismissEvent(event_id);
				});
				this.events = [];
			},
			getEvents: function () {

				if (!this.user_is_auth)
					return [];

				return api.get('/common/events')
				.then((response) => {

					let list = [];
					CommonService.log('debug', 'getEvents()::axios', response.data);

					if (response.status == 200 && response.data.success) {

						if (response.data.count > 0)
							list = response.data.events;

					}

					return list;
				}).catch(function (error) {

					CommonService.log('debug', 'getEvents()::axios', error);

					return [];
				});
			},
			setObjectTransferStatus: function (state, event_id, object_id) {
				api.post('/common/events', {
					type: 'transfer-object',
					state: state,
					event_id: event_id,
					object_id: object_id,
				})
				.then((response) => {

					CommonService.log('debug', 'setObjectTransferStatus()::axios', response.data);

					if (response.status == 200 && response.data.success) {
						this.dismissEvent('event_' + event_id);
						this.$emit('updateObjects');
					}

				}).catch(function (error) {

					CommonService.log('debug', 'setObjectTransferStatus()::axios', error);

				});
			},
			setClientTransferStatus: function (state, event_id, sender_id, client_id, req_id, tr) {
				api.post('/common/events', {
					type: 'transfer-client',
					state: state,
					event_id: event_id,
					sender_id: sender_id,
					client_id: client_id,
					requisition_id: req_id,
					tr: tr,
				})
				.then((response) => {

					CommonService.log('debug', 'setClientTransferStatus()::axios', response.data);

					if (response.status == 200 && response.data.success) {
						this.dismissEvent('event_' + event_id);
						this.$emit('updateClients');
					}
				}).catch(function (error) {

					CommonService.log('debug', 'setClientTransferStatus()::axios', error);

				});
			},
			filterEvents(events) {
				return events.filter(event => { this.hasViewedEvent(event.id) == true });
			},
			reCalcItem(list) {

				let _this = this;

				let viewed = [];
				if (!_this.isEmpty(_this.$store.getters.viewedEvents))
					viewed = _this.$store.getters.viewedEvents;

				let events = [];
				list.forEach(item => {
					if (!_this.inArray('event_' + item.id, viewed))
						events.push(item);
				});

				_this.events = events;
			},
        },
		mounted() {

			let _this = this;
			if (_this.timeout) {
				setInterval(() => {
					if (_this.user_is_auth) {
						_this.getEvents().then((list) => {

							let viewed = [];
							if (!_this.isEmpty(_this.$store.getters.viewedEvents))
								viewed = _this.$store.getters.viewedEvents;

							let events = [];
							list.forEach(item => {
								if (!_this.inArray('event_' + item.id, viewed))
									events.push(item);
							});

							_this.events = events;
						});
					}
				}, _this.timeout);
			}
		},
		computed: {
			count() {
				return this.events.length;
			},
			timeout() {
				let timeout = false;
				if (parseInt(this.$root.config.get_events_timeout))
					timeout = parseInt(this.$root.config.get_events_timeout) * 1000;

				return timeout;
			},
			user_is_auth() {

				if (!this.isEmpty(this.$store.getters.userInfo.id))
					return true;

				return false;
			},
		},
	};
</script>